/* You can add global styles to this file, and also import other style files */
@font_family: "Proxima Nova", HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, "sans-serif";
.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
}

.ui-progress-spinner-circle {
  stroke: #00afdb !important;
}

use {
	pointer-events: none;
}

body, p-table{
	font-weight: normal;
	font-family: "benton-sans-condensed", sans-serif;
}

.healthcare-top-bar-button {
	color: white !important;
}
#healthcare-top-bar {
	.healthcare-top-bar-button {
		&:hover {
			color: #00afdb;
			background-color: #0086a8 !important;
			border-color: #00afdb;
			transition: background-color 100ms;
		}
	}
}
.healthcareLeader {
	background: url('/assets/images/healthcare.jpg');
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	filter: "progid: DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/healthcare.jpg', sizingMethod='scale')";
	background-size: cover;
	margin-bottom: 80px;
	text-align: center;
	h1 {
		color: white;
		font-size: 54px;
		line-height: 1.0em;
		text-shadow: 0px 0px 5px rgba(0, 0, 1, 0.4);
		letter-spacing: -0.02em;
		font-weight: bold;
		padding-top: 128px;
		font-family: @font_family;
		margin: 0px 15px;
	}
	h2 {
		color: white;
		font-size: 26px;
		line-height: 1.0em;
		text-shadow: 0px 0px 5px rgba(0, 0, 1, 0.4);
		letter-spacing: -0.02em;
		padding-bottom: 128px;
		font-family: @font_family;
		margin: 15px;
	}
	div {
		background-color: #0095ba;
		padding: 50px 0px;
		span {
			color: white;
			font-family: @font_family;
			font-size: 24px;
			line-height: 31.2px;
			max-width: 1140px;
			margin: 15px;
			text-align: left;
			display: inline-block;
		}
	}
}
.inm-input, .inm-input__field, .inm-input--warning {
	font-size: 1em;
	font-weight: normal;
	font-family: sans-serif !important;
	color: #000;
	width:80%;
}
.inm-input--warning {
	border-color: #e2623d;
}
.form .ui-dropdown {
	width:80% !important;
  }
.ui-inputtext{ 
	height:35px; 	 
	padding-top: 8px;
}

.form-button-strip{ 
	float:right; 
}
.hspacer {
	height:10px;
}

.danger{
	color: #e2623d;
}

svg .disabled{
color: black;
}
.ui-dropdown .ui-state-focus {
	border: 1px solid #000;
}

// Table Classes
.default {
	text-align: center;
}

.money {
	text-align: right;
  overflow: auto;
}

.yesno-hdr {
	text-align: center;
	width: 75px;
}

.overflow {
	text-align: center;
	overflow: unset;
	white-space: unset;
	overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.column-icon-btn {
	margin: 0;
	text-align: center;
	width: 80px;
}

.grid-checkbox {
	width: 35px;
	text-align: center;
}

.ui-paginator .ui-paginator-page {
	width: 3.7em;
}

.space-it {
	margin-right: 15px;
}

.col-link {
	cursor:pointer;
	word-wrap: break-word;
	overflow-wrap: break-word;
}

.btn-container-dialog {
	display: flex;
	flex-flow: row;
	justify-content: flex-end;
	flex-basis: auto;
	flex-grow: 1;
	-webkit-box-pack: justify;
	-webkit-justify-content: flex-end;

	margin-top: 20px;
	margin-left: 8px;
}

.btn-padding {
	margin-left: 15px;
} 

p-accordiontab > div > a{
	display: flex !important;
	flex-direction: row !important;
}

:host ::ng-deep .ui-fileupload-content {
  overflow-y: auto !important;
}

.ui-button-text-icon-left, .ui-fileupload-choose.ui-button, .ui-fileupload-choose.ui-button .ui-widget, .ui-fileupload-choose.ui-button .ui-state-default {
	background: #fff !important;
	color: #038489 !important;
	border: 0.1rem solid transparent;
	border-color: currentColor;
	font-family: "benton-sans-condensed", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 1em;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 3.6rem;
  -webkit-transition: all 0.125s ease-out;
  transition: all 0.125s ease-out;
  outline: none;
  -webkit-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
  white-space: nowrap;
  border-radius: 1.1em;
}

.ui-fileupload-choose.ui-button:hover, .ui-fileupload-choose.ui-button:focus, .ui-fileupload-choose.ui-button.ui-state-active {
  -webkit-box-shadow: 0 0.6rem 2.2rem -1rem #038489 !important;
  box-shadow: 0 0.6rem 2.2rem -1rem #038489 !important;
}

.ui-button-text-icon-left:hover, .ui-button-text-icon-left:focus, .ui-button-text-icon-left.ui-state-active {
  -webkit-box-shadow: 0 0.6rem 2.2rem -1rem #038489;
  box-shadow: 0 0.6rem 2.2rem -1rem #038489;
}

.ui-listbox-header {
	height: 31px !important;
}

.ui-inputtext {
  margin-top: 0px;
}
_:-ms-fullscreen, .ui-inputtext {
  margin-top: 2px;
}

body .ui-progressbar {
	height: 6px;
}

body .ui-widget{
	font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size: 1em;
}

.client-droplist .ui-dropdown{
	width:217px;
	margin-right:18px;
	display:inline-block;
}

body .ui-fileupload .ui-fileupload-buttonbar .ui-button{
	border-radius: 1.1em;
}

body .ui-panel{
	border: 1px solid #D5D5D5;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
	background-color: white;
	border:0;
	color:black;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon{
	color:#848484;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a .ui-accordion-toggle-icon{
	color:#848484;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a{
	background-color: white;
	border:2px solid black;
	color:black;
}

// body .ui-accordion .ui-accordion-header:not(.ui-state-disabled) :not(.ui-state-active):hover a{
// 	// color:black;
// }

body .ui-accordian .ui-accordian-header a{
	background-color: white;
	border: 0;
}

body .ui-accordion .ui-accordion-header a:focus{
	box-shadow: none;
	border: 2px solid black;
}

body .ui-accordion .ui-accordion-header{
	border-top: 1px solid #d9d9d9;
}

body .ui-accordion .ui-accordion-content{
	border:0;
}
tbody a{
	text-decoration: underline;
	color:blue;
}

body .ui-panel .ui-panel-content{
    border:0;
}

body .ui-table .ui-table-thead > tr > th{
	padding:0.25em 0.5em;
	background-color: #ebedf0;
}

body .ui-table .ui-table-tbody > tr > td {
	padding:0.25em 0.25em;
}

.ui-widget-header{
	background:linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page{
	border-radius: 3px;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active{
	background-color: #186ba0;
	border-color: #156090;
}

.inm-spaced>*+*{
	margin-left:0;
}